.office {
  width: 100%; /* 图片宽度为100% */
  height: auto; /* 高度自适应 */
  margin-top: 80px; /* 顶部边距 */
  object-fit: contain; /* 更改为contain以避免裁剪 */
  object-position: center center; /* 将图片置中显示 */
}

@media only screen and (max-width: 600px) {
  .office {
    margin-top: 40px; /* 在小屏设备上减少顶部边距 */
  }
}

.it-consulting-block {
  background-color: #4F9FFF;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 1000px;
  margin: 0 auto;
  text-align: left; /* Add this line */
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 100px;
  opacity: 0;
  //transform: translateX(-100px); // Start from -100px on the X-axis
  animation: fadeInLeft 1s ease-out forwards;
  animation-delay: 0.3s; // You can adjust the delay to control when the animation starts
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%); // 元素从左侧开始，完全不可见
  }
  100% {
    opacity: 1;
    transform: translateX(0); // 元素移动到原始位置并完全可见
  }
}

.it-consulting-block h2 {
  font-size: 28px;
  margin-bottom: 10px;
}



.it-consulting-block p {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 40px;
}

.find-out-more-btn {
  background-color: #FF834F;
  color: #FFFFFF;
  border: none;
  padding: 10px 18px;
  font-size: 20px;
  cursor: pointer;
  //display: block; /* Add this line */
  //margin-right: 0; /* Add this line */
  align-self: flex-end;
  font-weight: bold;
  border-radius: 5px;
  display: inline-block;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .it-consulting-block {
    padding: 20px;
  }

  .it-consulting-block h2 {
    font-size: 24px;
  }

  .it-consulting-block p {
    font-size: 16px;
  }

  .find-out-more-btn {
    font-size: 16px;
    padding: 10px 20px;
    right: 20px;
    bottom: 20px;
  }
}


.background-image-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.office {
  width: 100%;
  height: auto;
}

.content-overlay {
  position: absolute;
  top: 70%;
  right: 10%;
  transform: translateY(-40%);
  width: 500px;
  max-width: 90%;
}


@media (max-width: 850px) {
  .it-consulting-block {
    display: none;
  }
}


@media screen and (max-width: 1600px) {

  .find-out-more-btn {

    padding: 8px 16px;
    //position: static;  // 移除定位，使用布局流
    margin-top: 10px;  // 为按钮添加上边距
    font-size: 18px;
    cursor: pointer;
    //display: block; /* Add this line */
    //margin-right: 0; /* Add this line */
    align-self: flex-end;
    font-weight: bold;
    border-radius: 5px;
    display: inline-block;
    text-decoration: none;
  }

  .content-overlay {
    position: absolute;
    top: 70%;
    right: 10%;
    transform: translateY(-40%);
    width: 480px;
    max-width: 90%;
  }

  .it-consulting-block {
    padding: 20px;
  }

  .it-consulting-block h2 {
    font-size: 24px;
  }

  .it-consulting-block p {
    font-size: 16px;
  }

}


@media screen and (max-width: 1500px) {

  .find-out-more-btn {
    padding: 8px 16px;
    //position: static;  // 移除定位，使用布局流
    margin-top: 10px;  // 为按钮添加上边距

    cursor: pointer;
    //display: block; /* Add this line */
    //margin-right: 0; /* Add this line */
    align-self: flex-end;
    font-weight: bold;
    border-radius: 5px;
    display: inline-block;
    text-decoration: none;
  }

  .content-overlay {
    position: absolute;
    top: 70%;
    right: 10%;
    transform: translateY(-45%);
    width: 500px;
    max-width: 90%;
  }
}

@media screen and (max-width: 1450px) {
  .it-consulting-block h2 {
    font-size: 24px;  // 适当减小字体大小以适应更小的显示
  }

  .it-consulting-block p {
    font-size: 16px;  // 减小段落文本的字体大小
    margin-bottom: 10px;
  }

  .find-out-more-btn {
    padding: 8px 16px;
    //position: static;  // 移除定位，使用布局流
    margin-top: 10px;  // 为按钮添加上边距

    cursor: pointer;
    //display: block; /* Add this line */
    //margin-right: 0; /* Add this line */
    align-self: flex-end;
    font-weight: bold;
    border-radius: 5px;
    display: inline-block;
    text-decoration: none;
  }

  .content-overlay {
    position: absolute;
    top: 70%;
    right: 10%;
    transform: translateY(-45%);
    width: 500px;
    max-width: 90%;
  }
}


@media screen and (max-width: 1400px) {
  .it-consulting-block h2 {
    font-size: 24px;  // 适当减小字体大小以适应更小的显示
  }

  .it-consulting-block p {
    font-size: 16px;  // 减小段落文本的字体大小
  }

  .find-out-more-btn {

    padding: 8px 16px;
    //position: static;  // 移除定位，使用布局流
    margin-top: 10px;  // 为按钮添加上边距

    cursor: pointer;
    //display: block; /* Add this line */
    //margin-right: 0; /* Add this line */
    align-self: flex-end;
    font-weight: bold;
    border-radius: 5px;
    display: inline-block;
    text-decoration: none;
  }

  .content-overlay {
    position: absolute;
    top: 70%;
    right: 10%;
    transform: translateY(-45%);
    width: 500px;
    max-width: 90%;
  }
}

@media screen and (max-width: 1350px) {
  .it-consulting-block h2 {
    font-size: 24px;  // 适当减小字体大小以适应更小的显示
  }

  .it-consulting-block p {
    font-size: 16px;  // 减小段落文本的字体大小
  }

  .find-out-more-btn {

    padding: 8px 16px;
    //position: static;  // 移除定位，使用布局流
    margin-top: 10px;  // 为按钮添加上边距
    font-size: 18px;
    cursor: pointer;
    //display: block; /* Add this line */
    //margin-right: 0; /* Add this line */
    align-self: flex-end;
    font-weight: bold;
    border-radius: 5px;
    display: inline-block;
    text-decoration: none;
  }

  .content-overlay {
    position: absolute;
    top: 70%;
    right: 10%;
    transform: translateY(-45%);
    width: 460px;
    max-width: 90%;
  }
}

@media screen and (max-width: 1250px) {
  .it-consulting-block h2 {
    font-size: 24px;  // 适当减小字体大小以适应更小的显示
  }

  .it-consulting-block p {
    font-size: 16px;  // 减小段落文本的字体大小

  }

  .find-out-more-btn {
    font-size: 14px;
    padding: 8px 16px;
    //position: static;  // 移除定位，使用布局流
    margin-top: 10px;  // 为按钮添加上边距
  }

  .content-overlay {
    position: absolute;
    top: 70%;
    right: 10%;
    transform: translateY(-40%);
    max-width: 90%;
  }
}



@media screen and (max-width: 1200px) {
  .it-consulting-block h2 {
    font-size: 20px;  // 适当减小字体大小以适应更小的显示
  }

  .it-consulting-block p {
    font-size: 16px;  // 减小段落文本的字体大小
  }

  .find-out-more-btn {
    font-size: 14px;
    padding: 8px 16px;
    //position: static;  // 移除定位，使用布局流
    margin-top: 10px;  // 为按钮添加上边距
  }

  .content-overlay {
    position: absolute;
    top: 70%;
    right: 10%;
    transform: translateY(-40%);
    max-width: 90%;
  }
}
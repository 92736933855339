.our-services {
  padding: 60px 0;
  background-color: #F2F2F2;
  //background-color: red; /* 测试用 */
  text-align: center;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  width: 100vw;
  margin-top: 100px; //这个调整和consultant block的距离
  margin-bottom: 160px; //这个调整和footer的距离

  @media only screen and (max-width: 728px) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.our-services-stripe {
  position: absolute;
  //top: calc(50% - 30px); // 使用calc来动态计算顶部位置
  transform: translateY(-50%);
  width: 100vw;
  height: calc(110% - 20px); // 这个调整stripe的宽度
  background-color: #F2F2F2;
  z-index: 1; // 保持条带在内容之下

  @media only screen and (max-width: 728px) {
    height: 18%; // 将高度调整为60%，使条带看起来更短
    width: 100vw; // 维持宽度充满视窗
    transform: translateY(3%); // 轻微向上调整位置以适应新的高度
    background-color: #F2F2F2;
    color: #000000;
    z-index: 1; // 保持层级不变
    //height: calc(110% - 40px); // 这个调整stripe的宽度

  }

}

.our-services h2 {
  font-family: Arial, sans-serif;
  // 这里是 our services部分
  font-size: 32px;
  margin-bottom: 20px;
  padding-top: 0;
  color: #FF834F;
  //font-weight: normal;
  font-weight: bold;
  padding-left: 100px; /* Aligns the heading with the text inside the cards */
  margin-left: 10px; /* Resets any default margin */
  text-align: left; /* Align text to the left */
}


.our-services-content {
  // our services下面的小字体

  line-height: 1.7em;
  font-size: 16px;
  max-width: 1200px; /* Adjust the max-width as needed */
  margin: 0 auto;
  padding: 0 20px;
  position: relative; // 添加了position属性
  z-index: 2; // 确保内容在条带上面
  text-align: left;

}


.our-services-content-p {
  // our services下面的小字体
  line-height: 1.7em;
  font-size: 16px;
  max-width: 1200px; /* Adjust the max-width as needed */
  padding-left: 105px; /* Aligns the heading with the text inside the cards */
  margin: 0 auto;
  margin-left: 10px;
  //background-color: white;
  position: relative; // 添加了position属性
  z-index: 2; // 确保内容在条带上面
  color: black;
  text-align: left;

}


.our-services > p {
  font-family: Arial, sans-serif;
  font-size: 18px;
  margin-bottom: 40px;
  color: black;
}

.service-cards {
  //font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
  color: black;
  margin-top: 40px;
}

.service-card {
  background-color: white;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 250px;
  text-align: left;
  margin-bottom: 30px;
}

.service-card h3 {
  font-size: 24px;
  margin-bottom: 10px;
  margin-top: 0;
  color: #FF834F;

  opacity: 0;
  animation: fadeIn 2s ease-out forwards;
  animation-delay: 1s; // You can adjust the delay to control when the animation starts

  @media only screen and (max-width: 728px) {
    font-size: 18px; // 减小字体大小以提高可读性
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.service-card p {
  font-size: 16px;
  margin-bottom: 20px;
  color: black;

  opacity: 0;
  animation: fadeIn 2s ease-out forwards;
  animation-delay: 1s; // You can adjust the delay to control when the animation starts

  font-family: Arial, sans-serif;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  unicode-bidi: isolate;
  @media only screen and (max-width: 728px) {
    font-size: 14px; // 减小字体大小以提高可读性
  }
}

.learn-more {
  font-family: Arial, sans-serif;
  text-decoration: none;
  font-size: 16px;
  color: #FF834F;

  opacity: 0;
  animation: fadeIn 2s ease-out forwards;
  animation-delay: 1s; // You can adjust the delay to control when the animation starts

  &:hover {
    color: black; // replace with the color you want on hover
  }
}


// 以下是对移动端的优化
@media only screen and (max-width: 600px) {
  .our-services-content {
    margin-top: -40px;
    padding: 10px; /* 在小屏设备上减少内边距 */
    max-width: 100%; /* 内容区使用全宽 */
    text-align: center; /* 文本居中显示，提高小屏幕阅读体验 */
  }

  .our-services-content h2 {
    //color: red; /* 测试用 */
    //font-family:  -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;;
    font-weight: bold;
    font-size: 24px; /* 减小标题字体大小 */
    padding-top: 20px; /* 调整标题的上内边距 */
    padding-left: 40px;
    //margin-bottom: 10px; /* 减少标题下的外边距 */
  }

  .our-services-content-p {
    //color: greenyellow; /* 测试用 */
    font-size: 14px; /* 减小段落字体大小 */
    line-height: 1.5em; /* 调整行高以提高可读性 */
    padding: 0 40px; /* 调整段落的左右内边距 */
  }

  .learn-more {
    font-family: Arial, sans-serif;
    font-size: 12px; /* 调整学习更多链接的字体大小 */
  }

}










.wrapper {
  position: relative;
  width: 100%; /* Full width of the container */
  height: 100px; /* Height should be enough to contain the .outer-frame */
  padding-top: 200px;
}

.outer-frame {
  position: absolute;
  border: 2px solid white;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateX(8px) translateY(15px) ; /* Center and shift 10px left */
  z-index: 1;
  width: 557px; /* Adjust the width as needed */
  height: 186px;
}

.inner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #F2F2F2;
  color: #1a2968;
  //border: 2px solid red;
  opacity: 0;
  animation: slideAndFadeIn 1s ease-out forwards;
  width: 547px; /* Adjust the width as needed */
  height: 186px;
  z-index: 2;

  display: flex;
  flex-direction: column;
  justify-content: center; /* This centers content vertically */
  align-items: center; /* This centers content horizontally */
  //height: 100%; /* Fill the height of its parent */
  text-align: center; /* Ensure text is centered horizontally */

}

/* ... other styles and keyframes remain the same ... */

@keyframes slideAndFadeIn {
  0% {
    transform: translate(-150%, -50%); /* Adjust starting position for the animation */
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}

.inner-container {
  h1 {
    line-height: 1em;
    font-size: 48px;
    font-weight: normal;
    vertical-align: baseline;
    overflow-wrap: break-word;
    text-align: left;
    width: 80%; /* 调整宽度 */
    margin: 0; /* 移除默认外边距 */

    position: relative; // 确保能相对定位伪元素

    &::after {
      content: ''; // 必需的，用来生成伪元素
      display: block;
      width: 80%; // 条纹的宽度，可以根据需要调整
      height: 3px; // 条纹的厚度
      background-color: #FF834F; // 条纹的颜色
      position: absolute;
      bottom: -10px; // 根据需要调整条纹与标题文字的距离
      left: 0; // 左对齐，与文字对齐
    }
  }



}

@media only screen and (max-width: 768px) {
  .outer-frame {
    //background-color: #61dafb;
    position: absolute;
    border: 2px solid white;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) translateX(8px) translateY(15px) ; /* Center and shift 10px left */
    z-index: 1;
    width: 300px; /* Adjust the width as needed */
    height: 100px;
  }

  .inner-container {
    //background-color: greenyellow;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-color: #F2F2F2;
    color: #1a2968;
    //border: 2px solid red;
    opacity: 0;
    animation: slideAndFadeIn 1s ease-out forwards;
    width: 300px; /* Adjust the width as needed */
    height: 100px;
    z-index: 2;

    display: flex;
    flex-direction: column;
    justify-content: center; /* This centers content vertically */
    align-items: center; /* This centers content horizontally */
    //height: 100%; /* Fill the height of its parent */
    text-align: center; /* Ensure text is centered horizontally */

  }



  .inner-container {
    h1 {
      line-height: 1em;
      font-size: 24px;
      font-weight: normal;
      vertical-align: baseline;
      overflow-wrap: break-word;
      text-align: left;
      width: 80%; /* 调整宽度 */
      margin: 0; /* 移除默认外边距 */

      position: relative; // 确保能相对定位伪元素

      &::after {
        content: ''; // 必需的，用来生成伪元素
        display: block;
        width: 80%; // 条纹的宽度，可以根据需要调整
        height: 3px; // 条纹的厚度
        background-color: #FF834F; // 条纹的颜色
        position: absolute;
        bottom: -10px; // 根据需要调整条纹与标题文字的距离
        left: 0; // 左对齐，与文字对齐
      }
    }
  }

  .wrapper {
    position: relative;
    width: 100%; /* Full width of the container */
    height: 100px; /* Height should be enough to contain the .outer-frame */
    padding-top: 30px;
  }
}

.slide-card-page {
  font-family: 'Arial', sans-serif;
}

.slide-card-header {
  height: 220px;

  @media (max-width: 768px) {
    height: 160px;; // Smaller font size on smaller screens
  }
}
@media only screen and (max-width: 768px) {
  .wrapper2 h2 {
    font-size: 1.3em;
  }
}

@media only screen and (max-width: 768px) {
  // 如果要保持和电脑页面的滑动窗口部分切入蓝色，那就用下面这个宽度
  .slide-card-header {
    color: white;
    height: 160px;
  }
  .wrapper2 p {
    line-height: 1.7em;
    font-size: 14px;
  }
}

.slide-card-content p {
  @media (max-width: 380px) { // 适应平板和手机尺寸
    font-size: 14px;
  }
}

.slide-card-content p {
  color: #333; /* Replace with the color you prefer */
  margin-bottom: 1em;
}

.slide-card-content {
  background-color: #F2F2F2
}



.slide-card-content {
  background-color: #f4f4f4;
  padding: 50px 0;
  @media (max-width: 768px) {
    padding: 30px 0; // Smaller font size on smaller screens
    padding-left: 30px;
  }

  .wrapper2 {
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 20px;
    p {
      font-size: 1em;
      color: #333;
      margin-bottom: 20px;
      //text-align: center;
      @media (max-width: 768px) {
        font-size: 1em; // Smaller font size on smaller screens
      }
    }
  }
}


.wrapper2 p {
  line-height: 1.7em;
}


.wrapper2 {
  width: 32%; // Set the width of the wrapper to 40%
  margin: 0 auto; // Center the wrapper horizontally
  //display: block;
  padding: 150px;

  @media (max-width: 1500px) { // 适应平板和手机尺寸
    width: 40%; // 增加宽度百分比
    margin: 0 auto;
  }

  @media (max-width: 1300px) { // 适应平板和手机尺寸
    width: 45%; // 增加宽度百分比
    margin: 0 auto;
  }

  @media (max-width: 1200px) { // 适应平板和手机尺寸
    width: 50%; // 增加宽度百分比
    margin: 0 auto;
  }

  @media (max-width: 1000px) { // 适应平板和手机尺寸
    width: 60%; // 增加宽度百分比
    margin: 0 auto;
  }

  @media (max-width: 900px) { // 适应平板和手机尺寸
    width: 65%; // 增加宽度百分比
    margin: 0 auto;
  }

  @media (max-width: 800px) { // 适应平板和手机尺寸
    width: 70%; // 增加宽度百分比
    margin: 0 auto;
  }
  @media (max-width: 700px) { // 适应平板和手机尺寸
    width: 75%; // 增加宽度百分比
    margin: 0 auto;
  }
  @media (max-width: 760px) { // 适应平板和手机尺寸
    width: 75%; // 增加宽度百分比
    margin: 0 auto;
    margin-left: -50px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @media (max-width: 700px) { // 适应平板和手机尺寸
    width: 80%; // 增加宽度百分比
    margin: 0 auto;
    margin-left: -110px;
  }
}


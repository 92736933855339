.footer {
  background-color: #002233;
  color: white;
  padding: 20px 0;
  padding-bottom: 40px;
  font-family: Arial, sans-serif;

}

.footer-container {
  display: flex;
  //justify-content: space-between; /* 如果添加了多媒体内容，使用这个 */
  justify-content: center; /* 如果只有一列，使用这个 */
  align-items: flex-start; /* 确保容器在交叉轴的起点对齐，也就是顶部 */
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-contact-info h4,
.footer-social-media h4 {
  margin-bottom: 10px;
}

.footer-contact-info p,
.footer-social-media a {
  margin-bottom: 5px;
  line-height: 1.6;
  color: white;
}

.footer-social-media a {
  text-decoration: none;
}

.footer-social-media a:hover {
  text-decoration: underline;
}

.footer-contact-info,
.footer-social-media {
  /* 如果需要，可以设置固定宽度或最大宽度 */
  width: 50%; /* 可能需要调整 */
  text-align: center; /* 文本居中 */
}


@media (max-width: 768px) {
  .footer-container {
    flex-direction: column; /* 在较小屏幕上切换到垂直布局 */
  }

  .footer-contact-info,
  .footer-social-media {
    width: 100%; /* 在垂直布局中宽度为100% */
    margin-bottom: 20px; /* 添加一些底部边距 */
  }

  .footer {
    padding: 10px 0;  /* 对于更小的屏幕，进一步减小内边距 */
  }
}


@media only screen and (max-width: 600px) {
  .footer {
    padding: 10px 5%; /* 在小屏幕上调整内边距 */
    font-size: 0.75rem; /* 在小屏幕上减小字体大小 */
  }
}

.text-link {
  color: inherit;  // 继承父元素的颜色
  text-decoration: inherit;  // 继承父元素的装饰
  font-size: inherit;  // 继承父元素的字体大小
  font-weight: inherit;  // 继承父元素的字体粗细
  line-height: inherit;  // 继承父元素的行高
  font-style: italic;  // 文字斜体
}


@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@500&display=swap');
.services-dropdown,
.industries-dropdown,
.case-studies-dropdown,
.solutions-dropdown {
  display: flex;
  position: absolute;
  background-color: #002233; // change as needed
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2); // for a subtle drop shadow
  padding: 20px;
  border-radius: 4px; // optional for rounded corners
  top: 100%; // position below the navbar link

  .dropdown-column {
    display: flex;
    flex-direction: column;
    padding: 0 10px;

    a {
      display: flex;
      align-items: center;
      color: #002233; // text color for dropdown
      padding: 5px 0; // spacing between links

      svg {
        margin-right: 10px; // spacing between icon and text
        width: 24px;
        height: 24px;
      }
    }
  }
}

.navbar-links li {
  position: relative; // Add this to the li
  .services-dropdown,
  .industries-dropdown,
  .case-studies-dropdown,
  .solutions-dropdown {
    position: absolute;
    top: 100%; // Dropdown directly below the link
    left: 0;
    // Rest of your styles...
  }
}


.navbar {
  display: flex;
  //justify-content: space-between;
  align-items: center;
  background-color: #002233;
  padding: 10px 50px;  /* 第一个值修改navbar的宽度，第二个值修改logo字体与左边界的距离 */

  width: 100%; /* 确保navbar的宽度为100% */
  font-family: 'Noto Sans', sans-serif;
  position: relative;
  z-index: 101;
  .navbar-logo {
    font-size: 26px; /* 修改logo 字体*/
    font-weight: bold;
    color: #FF834F;

    a {
      color: #FF834F;
      text-decoration: none;
    }
  }

  .navbar-links {
    list-style: none;
    display: flex;
    font-family: 'Noto Sans', sans-serif;
    li {
      margin-left: 20px;

      a {
        color: #ffffff;
        font-size: 20px;
        text-decoration: none;
        transition: color 0.3s;

        &:hover {
          color: #00adee; // Example hover color
        }
      }
    }
  }
}
.services-dropdown,
.solutions-dropdown,
.industries-dropdown,
.case-studies-dropdown {
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s ease, visibility 0s linear 1s;
  z-index: 100;
}
.services-dropdown.visible,
.solutions-dropdown.visible,
.industries-dropdown.visible,
.case-studies-dropdown.visible {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}

.services-dropdown.hidden,
.solutions-dropdown.hidden,
.industries-dropdown.hidden,
.case-studies-dropdown.hidden {
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s ease, visibility 0s linear 1s;
}
.services-dropdown,
.industries-dropdown,
.case-studies-dropdown,
.solutions-dropdown {
  display: flex;
  flex-direction: column;
  position: absolute;
  //background-color: #f9f9f9;
  min-width: 250px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}
.services-dropdown a,
.industries-dropdown a,
.case-studies-dropdown a,
.solutions-dropdown a {
  color: black;
  padding: 12px 20px;
  text-decoration: none;
  display: block;
  white-space: nowrap;
  text-align: left; /* Align the text to the left */
}
.services-dropdown a:hover,
.industries-dropdown a:hover,
.case-studies-dropdown a:hover,
.solutions-dropdown a:hover {
  //background-color: #f1f1f1;
}


.hamburger {
  display: none; // 默认隐藏
  cursor: pointer;
  font-size: 24px; // 调整大小适合触摸屏
}

.navbar-links {
  display: none;
}



@media only screen and (max-width: 768px) {
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #002233;
    color: white;
    padding:0;

  }

  .navbar-logo, .hamburger {
    flex-grow: 1; // 允许元素根据需要扩展和收缩
    text-align: left; // 左对齐 .navbar-logo 文本，右对齐 .hamburger 文本在下面调整
  }

  .navbar-logo a {
    display: block; // 让 a 标签填满 .navbar-logo 的整个宽度
    font-size: 24px;
    text-decoration: none; // 去除下划线
    padding: 20px 40px; // 根据实际需要调整间距
  }

  .hamburger {
    display: block;
    text-align: right; // 文本右对齐
    color: white;
    padding: 20px 40px; // 根据实际需要调整间距
  }

  .navbar-logo a, .hamburger {
    padding: 20px 40px; /* 根据实际需要调整间距 */
  }

  .services-dropdown,
  .industries-dropdown,
  .case-studies-dropdown,
  .solutions-dropdown {
    position: static; /* Adjust dropdown positioning on mobile */
  }


  .navbar-links {
    flex-direction: column;
    align-items: flex-start; /* Align links to the start */
    display: none;// 确保默认是隐藏的
    //flex-direction: column;
    width: 100%;
  }

  .navbar-links.show {
    display: flex;  // 当添加了.show类时才显示
  }

  .navbar-links li {
    margin: 5px 0; /* Stack links vertically with space */
    font-size: 20px;
  }

  body .navbar .navbar-links li a {
    padding: 5px 15px 8px 20px; /* Larger touch area */
    display: block; /* Make the links full width */
    font-size: 18px;
  }

  .navbar ul {
    list-style: none;
    position: absolute;
    top: 100%; /* 从导航栏底部开始 */
    left: 0;
    width: 100%; /* 菜单宽度与导航栏相同 */
    background-color: #002233; /* 背景颜色与导航栏一致 */
    color: white; /* 文本颜色 */
    display: none; /* 默认不显示 */
    padding: 0;
    margin: 0;
    box-shadow: 0 8px 16px rgba(0,0,0,0.2); /* 可选阴影效果 */
    padding-bottom: 10px;
  }


  body .navbar .navbar-links li {
    position: relative; // Add this to the li
    .services-dropdown,
    .industries-dropdown,
    .case-studies-dropdown,
    .solutions-dropdown {

      position: absolute;
      //top: 100%; // Dropdown directly below the link
      left: 20px;
      //background-color: red;
      padding: 10px 40px 10px 5px;

      a {
        font-size: 16px;
        margin-bottom: 6px;
      }
      // Rest of your styles...
    }
  }
}


@media (min-width: 769px) {
  .navbar-links {
    display: flex;
    flex-direction: row;
  }
}

.img-zoom {
  transform: scale(1.2);
  transition: transform 0.3s ease; /* 平滑过渡效果 */
  display: block;
  margin: auto;
  transform-origin: left;
  margin-left: -10px;

  @media (max-width: 768px) {
    transform: scale(1.4);
    margin-left: -25px;
  }

}

.img-zoom:hover {
  transform: scale(1.8); /* 鼠标悬停时放大 1.5 倍 */

  @media (max-width: 768px) {
    transform: scale(1.4);

  }
}
html, body {
  height: 100%; /* 使html和body元素的高度至少与视口一样高 */
  margin: 0; /* 移除默认的外边距 */
  background-color: #002233; /* 你想要的背景颜色 */
}

body {
  min-height: 100vh; /* 设置最小高度为视口的高度 */
  /* 其他样式保持不变 */
}

.rl-page {
  font-family: 'Arial', sans-serif;
}

.rl-content {
  background-color: #F2F2F2;
}


.rl-content {
  min-height: calc(100vh - 450px);
  @media (max-width: 800px) { // 适应平板和手机尺寸
    min-height: calc(100vh - 450px);
  }
  @media (max-width: 780px) { // 适应平板和手机尺寸
    min-height: calc(100vh - 430px);
  }
  @media (max-width: 760px) { // 适应平板和手机尺寸
    min-height: calc(100vh - 420px);
  }

  @media (max-width: 600px) { // 适应平板和手机尺寸
    min-height: calc(100vh - 390px);
  }
  @media (max-width: 440px) { // 适应平板和手机尺寸
    min-height: calc(100vh - 390px);
  }
  @media (max-width: 420px) { // 适应平板和手机尺寸
    min-height: calc(100vh - 390px);
  }

  @media (max-width: 376px) { // 适应平板和手机尺寸
    min-height: calc(100vh - 390px);
  }

  @media (max-width: 320px) { // 适应平板和手机尺寸
    min-height: calc(100vh - 400px);
  }
}


.rl-content p {
  color: #333; /* Replace with the color you prefer */
  margin-bottom: 1em;
  @media (max-width: 1500px) { // 适应平板和手机尺寸
    width: 40%; // 增加宽度百分比
    margin-left: -40px;
  }

  @media (max-width: 1300px) { // 适应平板和手机尺寸
    width: 45%; // 增加宽度百分比
    margin-left: -50px;
  }

  @media (max-width: 1200px) { // 适应平板和手机尺寸
    width: 50%; // 增加宽度百分比
    margin-left: -70px;
  }

  @media (max-width: 1000px) { // 适应平板和手机尺寸
    width: 70%; // 增加宽度百分比
    margin-left: -80px;
  }

  @media (max-width: 900px) { // 适应平板和手机尺寸
    width: 70%; // 增加宽度百分比
    margin-left: -80px;
  }

  @media (max-width: 800px) { // 适应平板和手机尺寸
    width: 70%; // 增加宽度百分比
    margin-left: -80px;
  }
  @media (max-width: 700px) { // 适应平板和手机尺寸
    width: 70%; // 增加宽度百分比
    margin-left: -75px;
  }
  @media (max-width: 760px) { // 适应平板和手机尺寸
    width: 70%; // 增加宽度百分比
    margin: 0 auto;
    margin-left: -80px;
  }
  @media (max-width: 700px) { // 适应平板和手机尺寸
    width: 80%; // 增加宽度百分比
    margin: 0 auto;
    margin-left: -90px;
  }
}


.rl-wrapper2-first-case {
  width: 32%; // Set the width of the wrapper to 40%
  margin: 0 auto; // Center the wrapper horizontally
  //display: block;
  padding-top: 50px;
}

.rl-wrapper2 {
  width: 32%; // Set the width of the wrapper to 40%
  margin: 0 auto; // Center the wrapper horizontally


}

.rl-wrapper2-last-case {
  width: 32%; // Set the width of the wrapper to 40%
  margin: 0 auto; // Center the wrapper horizontally
  //display: block;
  padding-bottom: 100px;
}

.rl-wrapper2 p {
  line-height: 1.7em;
}


.rl-content .case-title {
  //color: #333;
  cursor: pointer; /* Indicates that the title is clickable */
  margin-bottom: 0.5em; /* Space below the title */
  font-weight: bold; /* Optional: Makes the title stand out */
  font-size: 20px; /* 标题字体比正文大 */
  color: #1a2968; /* 使用一个深色的标题颜色以和正文区分 */
  width: 1000px;

}

.rl-content .case-details {
  width: 800px; /* 例如，设置一个固定宽度500px */
  margin: 0 auto; /* 同样，加上这个使得盒子在页面中心对齐 */

  display: none; /* Initially hidden */
  color: #333;
  border-top: 1px solid #ddd; /* Optional: Adds a subtle line to separate title from details */
  border-radius: 15px; /* 添加边角的圆滑度 */
  font-family: 'Open Sans', Arial, sans-serif; /* 使用通用的易读字体 */

  background-color: #f5f5f5; /* 一个浅灰色的背景可以减少眼睛的疲劳 */
  padding: 20px; /* 添加内边距 */

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 轻微的阴影增加层次感 */

  margin-left: -100px;
}

.rl-content .case-details p {
  font-size: 16px; /* 保持一个适中的字体大小 */
  line-height: 1.6; /* 行间距1.6倍于字体大小，提高可读性 */
  margin-bottom: 1em; /* 每个段落底部添加间距 */
  //text-align: left; /* 左对齐文本 */
  color: #333;
  display: block;
}

.rl-content .case-inner-title {
  font-weight: 600; /* 字体加粗 */
  font-size: 22px; /* 增大字体大小 */
  color: #1a2968; /* 标题颜色 */
  margin-bottom: 15px; /* 标题与下文之间的间距 */
  padding-bottom: 10px; /* 标题底部的内边距 */
  border-bottom: 2px solid #ddd; /* 标题下方的边框线 */

  text-align: center; /* 使文本居中 */
  display: inline-block; /* 确保元素是块级元素 */
  width: 100%; /* 设置宽度，使之可以居中 */
}

.rl-content .case-details-visible {
  display: block;

}

.rl-content .case-details span {
  display: block; /* 让span表现得像块级元素 */
  margin-top: 8px; /* 这会在每个 <br /> 后添加额外的空间 */
}

.img-container {
  width: 100%; /* 设置容器宽度为100% */
  height: auto;
  padding: 0; /* 如果有必要，移除内边距 */
  margin: 0; /* 如果有必要，移除外边距 */
}


@media (max-width: 1500px) {
  .rl-content .container {
    width: auto; /* 在小屏幕上使用更大的百分比宽度 */
    padding: 10px; /* 减少内边距可能有帮助 */
  }

  .rl-content .case-title {
    font-size: 20px;
    width: 800px;
  }

  .rl-content .case-details {
    //padding: 10px; /* 减小内边距 */
    // 可能还需要调整其他样式，比如字体大小等
    width: 800px;
    //margin: auto;
    margin-left: -100px;
    //color:blue;
  }
  .rl-content .case-details h2 {
    font-size: 20px;
    margin-left: -5px;
    //color: red;

  }
  .rl-content .case-details p {
    //padding: 10px; /* 减小内边距 */
    // 可能还需要调整其他样式，比如字体大小等
    //margin: auto;
    margin-left: -5px;
    //color:red;
    word-wrap: break-word; /* 允许单词在末尾断行 */
    white-space: normal; /* 确保使用默认的换行规则 */
    overflow-wrap: break-word; /* 保证即使是无间隔的长字符串也能断行 */
    width: 800px;
    font-size: 16px;

  }

  .rl-content .case-details span {
    font-size: 16px;
  }

  .rl-content .case-inner-title {
    font-size: 20px;
    text-align: center;
    margin-left: -5px;
  }
}


.img-container img {
  max-width: 100%; /* 确保图片不会超出其容器的宽度 */
  height: auto; /* 保持图片比例 */
  display: block; /* 防止图片下方有额外空间 */
}


@media (max-width: 1300px) {

  .rl-content .container {
    width: auto; /* 在小屏幕上使用更大的百分比宽度 */
    padding: 10px; /* 减少内边距可能有帮助 */
  }

  .rl-content .case-title {
    font-size: 20px;
    width: 800px;
  }

  .rl-content .case-details {
    //padding: 10px; /* 减小内边距 */
    // 可能还需要调整其他样式，比如字体大小等
    width: 600px;
    //margin: auto;
    margin-left: -100px;
    //color:blue;
  }
  .rl-content .case-details h2 {
    font-size: 15px;
    margin-left: -5px;
    //color: red;

  }
  .rl-content .case-details p {
    //padding: 10px; /* 减小内边距 */
    // 可能还需要调整其他样式，比如字体大小等
    //margin: auto;
    margin-left: -5px;
    //color:red;
    word-wrap: break-word; /* 允许单词在末尾断行 */
    white-space: normal; /* 确保使用默认的换行规则 */
    overflow-wrap: break-word; /* 保证即使是无间隔的长字符串也能断行 */
    width: 600px;
    font-size: 12px;

  }

  .rl-content .case-details span {
    font-size: 12px;
  }

  .rl-content .case-inner-title {
    font-size: 20px;
    text-align: center;
    margin-left: -5px;
  }
}


.img-container img {
  max-width: 100%; /* 确保图片不会超出其容器的宽度 */
  height: auto; /* 保持图片比例 */
  display: block; /* 防止图片下方有额外空间 */
}


@media (max-width: 900px) {

  .rl-content .container {
    width: auto; /* 在小屏幕上使用更大的百分比宽度 */
    padding: 10px; /* 减少内边距可能有帮助 */
  }

  .rl-content .case-title {
    font-size: 20px;
    width: 400px;
  }

  .rl-content .case-details {
    //padding: 10px; /* 减小内边距 */
    // 可能还需要调整其他样式，比如字体大小等
    width: 600px;
    //margin: auto;
    margin-left: -100px;
    //color:blue;
  }
  .rl-content .case-details h2 {
    font-size: 15px;
    margin-left: -5px;
    //color: red;

  }
  .rl-content .case-details p {
    //padding: 10px; /* 减小内边距 */
    // 可能还需要调整其他样式，比如字体大小等
    margin-left: -5px;
    word-wrap: break-word; /* 允许单词在末尾断行 */
    white-space: normal; /* 确保使用默认的换行规则 */
    overflow-wrap: break-word; /* 保证即使是无间隔的长字符串也能断行 */
    width: 600px;
    font-size: 12px;

  }

  .rl-content .case-details span {
    font-size: 12px;
  }

  .rl-content .case-inner-title {
    font-size: 20px;
    text-align: center;
    margin-left: -5px;
  }

}


.img-container img {
  max-width: 100%; /* 确保图片不会超出其容器的宽度 */
  height: auto; /* 保持图片比例 */
  display: block; /* 防止图片下方有额外空间 */
}


@media (max-width: 760px) {
  .rl-content .container {
    width: auto; /* 在小屏幕上使用更大的百分比宽度 */
    padding: 10px; /* 减少内边距可能有帮助 */
  }

  .rl-content .case-details {
    //padding: 10px; /* 减小内边距 */
    // 可能还需要调整其他样式，比如字体大小等
    width: 400px;
    //margin: auto;
    margin-left: -100px;
    //color:blue;
  }
  .rl-content .case-details p {
    //padding: 10px; /* 减小内边距 */
    // 可能还需要调整其他样式，比如字体大小等
    //margin: auto;
    margin-left: -5px;
    //color:red;
    word-wrap: break-word; /* 允许单词在末尾断行 */
    white-space: normal; /* 确保使用默认的换行规则 */
    overflow-wrap: break-word; /* 保证即使是无间隔的长字符串也能断行 */
    width: 400px;
  }

  .rl-content .case-inner-title {
    font-size: 20px;
    text-align: left;
  }


  .rl-wrapper2-first-case {
    width: 32%; // Set the width of the wrapper to 40%
    margin: 0 auto; // Center the wrapper horizontally
    //display: block;
    padding-top: 50px;
  }
}


@media (max-width: 500px) {

  .rl-content .container {
    width: auto; /* 在小屏幕上使用更大的百分比宽度 */
    padding: 10px; /* 减少内边距可能有帮助 */
  }

  .rl-content .case-title {
    font-size: 15px;
    width: 300px;
  }

  .rl-content .case-details {
    //padding: 10px; /* 减小内边距 */
    // 可能还需要调整其他样式，比如字体大小等
    width: 300px;
    //margin: auto;
    margin-left: -100px;
    //color:blue;
  }
  .rl-content .case-details h2 {
    font-size: 15px;
    margin-left: -5px;

  }
  .rl-content .case-details p {
    //padding: 10px; /* 减小内边距 */
    // 可能还需要调整其他样式，比如字体大小等
    //margin: auto;
    margin-left: -5px;
    //color:red;
    word-wrap: break-word; /* 允许单词在末尾断行 */
    white-space: normal; /* 确保使用默认的换行规则 */
    overflow-wrap: break-word; /* 保证即使是无间隔的长字符串也能断行 */
    width: 300px;
    font-size: 12px;
  }

  .rl-content .case-details span {
    font-size: 12px;
  }

  .rl-content .case-inner-title {
    font-size: 15px;
    text-align: left;
    margin-left: -5px;
  }


}


.img-container img {
  max-width: 100%; /* 确保图片不会超出其容器的宽度 */
  height: auto; /* 保持图片比例 */
  display: block; /* 防止图片下方有额外空间 */
}

.about-page {
  // Your existing styles
}

.about-header {
  // Your existing styles
  //padding: 20px 0;
  height: 220px;

  @media (max-width: 768px) {
    height: 160px;; // Smaller font size on smaller screens
  }
}

.about-content {
  background-color: #f4f4f4;
}

.why-us-content {
  background-color: #f4f4f4;
  padding: 50px 0;
  @media (max-width: 768px) {
    padding: 30px 0; // Smaller font size on smaller screens
    padding-left: 30px;

  }
  .why-us-wrapper {
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 20px;
    h2 {
      font-size: 2.5em;
      color: #333;
      margin-bottom: 20px;
      text-align: center;
      @media (max-width: 768px) {
        font-size: 2em; // Smaller font size on smaller screens
      }
    }
    .why-us-section {
      margin-bottom: 40px;
      h3 {
        font-size: 1.8em;
        color: #555;
        margin-bottom: 15px;
        @media (max-width: 768px) {
          font-size: 1.5em; // Smaller font size on smaller screens
        }
      }
      ul {
        list-style-type: none;
        padding: 0;
        li {
          font-size: 1.2em;
          margin-bottom: 10px;
          line-height: 1.7em;
          @media (max-width: 768px) {
            font-size: 1em; // Smaller font size on smaller screens
          }
          strong {
            color: #333;
          }
        }
      }
    }
  }
}

.consultation-wrapper2 p {
  line-height: 1.7em;
}

html, body {
  overflow-x: hidden; // Prevent horizontal scroll
}

.consultation-content p {
  @media (max-width: 768px) { // Adapt for tablet and mobile sizes
    font-size: 14px;
  }
}

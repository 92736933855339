.App {
  text-align: center;
  width: 100%; /* 确保.App填满屏幕宽度 */
  min-width: 10px; /* 设置一个最小宽度，防止过于窄 */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


@media only screen and (max-width: 600px) {
  .App-header {
    min-height: 90vh; /* 减少最小高度以适应屏幕 */
  }

  .App-logo {
    height: 20vmin; /* 在小屏幕上减小logo高度 */
  }

  .footer {
    padding: 10px 5px;
    font-size: 0.75rem; /* 调整字体大小 */
  }

  .office {
    margin-top: 60px; /* 减小间距适应小屏幕 */
  }
}

.App-link {
  color: #61dafb;
}


@media only screen and (max-width: 600px) {
  .App-header {
    min-height: 90vh;
  }

  .App-logo {
    height: 20vmin;
  }

  .footer {
    padding: 10px 5px;
    font-size: 0.75rem;
  }

  .office {
    margin-top: 60px;
  }
}


@media only screen and (max-width: 600px) {
  .App {
    width: 100%;
    padding: 0px; /* 减小内边距 */
    background-color: #282c34; /* 确保背景颜色一致 */
  }

  .App > * {
    margin: 0; /* 去除外边距 */
    padding: 0; /* 减小内边距 */
  }

  .App-nav, .App-content {
    font-size: 12px; /* 减小字体大小 */
  }

  /* 调整导航栏和内容区域的布局 */
  .App-nav {
    display: flex;
    flex-direction: column; /* 在小屏幕上改为垂直排列 */
  }

  .App-content {
    padding: 5px; /* 调整内边距 */
  }
}
